// export const API_ENDPOINT = 'https://site-api.curoflow.se';
export const API_ENDPOINT = 'https://qaapi.curoflow.se';
// export const API_ENDPOINT = 'http://localhost:38521';
export const recaptchaAPIKey = '6Lf8kNolAAAAAPwQ3NfAldaFAfkD-Au4KgSWgAuP'
//kada se dize na qa api_endpoint je qaapi.curoflow.se a kada se dize na curoflow.se api_endpoint je site-api.curoflow.se
// export const SITE_URL = 'https://qa.curoflow.se'
export const SITE_URL = 'https://curoflow.se'


export const ReCaptchaSiteKey = '6Lf8kNolAAAAAPwQ3NfAldaFAfkD-Au4KgSWgAuP';
