import React, { Component } from 'react';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import logoC from '../assets/images/curoflow_telemedicine_platform_logo.png'
import { Link } from 'react-router-dom';
import SupportForm from '../components/forms/supportForm';

class ThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }
    loadScript =  (src) => {
        let tag = window.document.createElement('script');
        tag.async = true;
        tag.src = src;
        let body = window.document.getElementsByTagName('body')[0];
        body.appendChild(tag);
      }
  
    

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.loadScript('https://www.clickcease.com/monitor/cccontrack.js')
    }
    sendMessage2 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {

                setTimeout(() => {
                    this.player?.current?.pause()
                }, 1120)

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status === 429) {
                        this.setState({
                            solutionsStatus: true,
                            loading2: false
                        })
                    }
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.props.bookDemo(false)
                        
                    }
                })
            })
        }
    }

    render() {
        return (
            <div>
                                <div className={this.props.openModalBookDemo  ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                         this.props.bookDemo(false)
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus?
                            <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>
               <section className='thankYou-section'>
                    <img src={logoC} alt="Curoflow telehealth platform logo"/>
                    <div className='thankYou-content'>
                    <h1>{"Thank you for contacting us".translate(this.props.lang)}!</h1>
                        <h4>{"Your message has been received and we will get back to you shortly".translate(this.props.lang)}.</h4>
                        <h4>{"Our office hours are Monday - Friday ​​08.30 - 17.00, with the exception of public holidays".translate(this.props.lang)}.</h4>
                        <h4>{"For urgent matters, call +46 (0)10-750 06 55".translate(this.props.lang)}.</h4>
                       <Link to="/"> <button className='thankYou-btn'>{"To the homepage".translate(this.props.lang)} </button> </Link>
                    </div>
               </section>
            </div>
        );
    }
}

export default Page(ThankYou);

